@import '../abstracts/mixins';
@import '../abstracts/variables';
mat-card.seneca-card {
  padding: 0;
  mat-card-header.seneca-card-header {
    min-width: 100%;
    height: 45px;
    padding: 0 2em;
    margin: 0 -24px;
    -webkit-box-sizing: unset;
    -moz-box-sizing: unset;
    box-sizing: unset;
    background-color: $dark-color;
    color: $white-color;
    mat-card-title.seneca-card-title {
      color: $white-color;
      @include seneca-fonts-common(400, 20px);
      white-space: nowrap;
      margin: 0;
    }
  }
  mat-card-content.seneca-card-content {
    @include seneca-fonts-common($medium-font-weight, $medium-font-size);
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin: 0;
  }
}
.seneca-card-form-page {
  margin-bottom: 2em;
}

$grey-color: #f2f2f2;
$seneca-default-text-color: #333333;
$dark-color: #55585a;
$white-color: #ffffff;
$main-font-family: 'Open Sans';
$secondary-font-family: 'minion pro';
$small-font-size: 14px;
$medium-font-size: 16px;
$large-font-size: 24px;
$xLarge-font-size: 30px;
$header-font-size: 40px;
$medium-font-weight: 400;
$semi-bold-font-weight: 500;
$bold-font-weight: 700;

@use "@angular/material" as mat;
@import "./theme";
@import "./base/base";
@import "./base/typography";
@import "./components/card";
@import "./components/checkbox";
@import "./components/form-stepper";
@import "./components/label";
@import "./components/table";
@import "./components/snackbar";
@import "./components/privacy-text";
@import "./base/ultilities";
@import "./components/modal";
@include mat.core();

// @include mat-checkbox-typography($custom-typography);

//@include mat.all-component-themes($custom-typography);

//@include angular-material-theme($seneca-app-theme);
@include mat.all-component-themes($seneca-app-theme);

// @include mat-card-typography($secondary-typography);

// @include mat-base-typography($custom-typography);

h1,
h2,
h3,
h4 {
  font-family: m2-font-family($secondary-typography);
  color: #55585a;
  font-weight: 400;
}

h1 {
  font-size: 30px;
  line-height: 42px;
}

h2 {
  font-size: 24px;
  line-height: 24px;
}

h3 {
  font-size: 20px;
  line-height: 28px;
}

.mat-flat-button.mat-primary:hover:enabled,
.mat-flat-button.mat-primary:focus:enabled {
  background: #841911;
}

.mat-flat-button.mat-primary:active:enabled {
  background: #da291c;
}

// overidding bootstrap
.mat-button-focus-overlay {
  background-color: transparent;
}

button:focus,
:focus {
  outline: 0;
}

button.seneca-button {
  border-radius: 25px;
  min-width: 120px;
  height: 45px;
  padding: 0 25px;
}

button.seneca-button.seneca-stroke-button:enabled {
  border-color: #da291c;
}

@media screen and (max-width: 601px) {
  mat-card-content img {
    width: 100%;
  }
}

// overidding bootstrap
.mat-button-focus-overlay {
  background-color: transparent;
}

button:focus,
:focus {
  outline: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
}

// layout
.seneca-list-container {
  padding: 1vw 2vw;
}

// tiny details
span.mat-form-field-label-wrapper {
  top: -0.6em;
}

label {
  margin: unset;
}

.seneca-elevation {
  box-shadow: 0px 3px 6px #00000029;
}

.mat-form-field {
  line-height: 1.2;
}

.mat-form-field-infix {
  border-top: 0.6em solid transparent;
}

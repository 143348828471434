@use "@angular/material" as mat;
@import "./seneca-palette.scss";
@import "./base/typography";
@include mat.core();

$seneca-app-theme-primary: mat.m2-define-palette($seneca-primary-red-palette);
$seneca-app-theme-accent: mat.m2-define-palette($seneca-primary-red-palette);
$seneca-app-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$seneca-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $seneca-app-theme-primary,
      accent: $seneca-app-theme-accent,
      warn: $seneca-app-theme-warn,
    ),
    typography: $custom-typography,
  )
);

.icon-green {
  color: #5df1a0;
}
.icon-red {
  color: #f5270c;
}
.icon-blue {
  color: #44d0fa;
}
.icon-gray {
  color: #c7c7c7;
}
.status-icon {
  margin-bottom: 0;
  padding-bottom: 0;
}
.status-table {
  width: auto;
}
.status-table td {
  text-align: left !important;
  margin: 0;
  padding-right: 3px;
}

.action-box {
  background: #f6fdf2ef;
  margin: 1px;
  padding: 3px;
  border-radius: 5px;
}

.status-label {
  margin-top: 2em;
  padding: 0.5em;
}
.float-right {
  float: right;
  clear: both;
}
.link-btn:link {
  text-decoration: none;
  border-radius: 25px;
  display: block;
}
.previewlist-btn-text {
  padding-top: 0.8em;
}
.preview-list-icon {
  margin-top: 0.35em;
}

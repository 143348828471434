@use "@angular/material" as mat;
@import "@angular/material/";
// Import Fonts
@import url("https://use.typekit.net/qpe1xuu.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
@include mat.core();

$custom-typography: mat.m2-define-typography-config(
  $font-family: '"Open Sans",sans-serif',
  $body-1: mat.m2-define-typography-level(16px, 22px, 400),
  $headline-1: mat.m2-define-typography-level(18px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(16px, 25px, 400),
  $button: mat.m2-define-typography-level(16px, 18px, 600),
);

$secondary-typography: mat.m2-define-typography-config(
  $font-family: '"minion-pro" , sans-serif',
  $headline-1: mat.m2-define-typography-level(30px, 42px, 400),
  $headline-2: mat.m2-define-typography-level(25px, 35px, 400),
  $subtitle-2: mat.m2-define-typography-level(16px, 28px, 400),
  $subtitle-1: mat.m2-define-typography-level(15px, 25px, 400),
);

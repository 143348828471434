@use "@angular/material" as mat;
@import "../theme.scss";
@import "../abstracts/variables";
@import "../seneca-palette.scss";
@include mat.core();
.vertical-padding {
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.pointer-cursor {
  cursor: pointer;
}

.reset-cursor {
  cursor: default;
}

.seneca-content-padding {
  padding: $large-font-size;
}

.remove-top-padding {
  padding-top: 0;
}

.center-align {
  text-align: center;
}

.senecaImportantText {
  color: #c20f09;
  //change color to seneca-app-theme-primary
}

.noOverflow,
.noOverflow .mat-checkbox-layout {
  white-space: normal;
}

.margin-left-medium {
  margin-left: 1.75em;
}

.senecaBold {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

@media print {
  body,
  html {
    height: auto !important;
    width: 100% !important;

    overflow: hidden !important;
  }
  table {
    /* Or specify a table class */
    max-height: 100% !important;
    overflow: hidden !important;
    page-break-after: always !important;
  }

  button {
    display: none !important;
  }

  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
  .intro {
    display: none !important;
  }
  .sub-header__title {
    display: none !important;
  }
  .app-number-container {
    justify-content: flex-end !important;
  }

  mat-toolbar {
    display: none !important;
  }
  .seneca-card-content {
    max-width: 100% !important;
  }

  .app-container {
    max-width: 100% !important;
  }

  .card-header-container {
    display: none !important;
  }
}
